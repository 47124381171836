import $ from 'jquery';
import {AjaxSync} from "./shared/ajax_utilities";

$(() => {
    if(!app.OPTIONS.list) {
        return;
    }

    const listViewsSelect = $('#list-to-show');
    listViewsSelect.unbind('change').on('change', (_e) => {
        const value = listViewsSelect.val();
        let ajaxOpts = {
            url: `${app.CACHE.URL_AJAX}saved_columns`,
            method: 'POST',
            data: {
                action: 'set_tbl_view',
                tbl:$('#list-to-show-section').val(),
            }
        };

        if(value === '') {
            ajaxOpts.data.action = 'clear_tbl_view';
        } else {
            ajaxOpts.data.id = value;
        }

        AjaxSync(ajaxOpts, {
            done: (res) => {
                if(res.status !== 'success') {
                    return;
                }

                window.location.reload();
            },
        });
    });
});